<template lang="pug">
div.space-y-4
  // Label
  CommonInput(
    v-model='label'
    name='label'
    label='Label'
    placeholder="What's your question ?"
    :required='true'
  )

  // Placeholder
  CommonInput(
    v-model='placeholder'
    name='placeholder'
    label='Input placeholder'
    placeholder='Write here'
    :required='true'
  )
</template>

<script setup lang="ts">
// const message = defineModel<string>({ required: true })
const props = defineProps<{
  label: string
  placeholder: string
}>()

const emit = defineEmits<{
  (e: 'update:label', value: string): void
  (e: 'update:placeholder', value: string): void
}>()

const label = computed({
  get() {
    return props.label
  },
  // setter
  set(newValue) {
    emit('update:label', newValue)
  },
})

const placeholder = computed({
  get() {
    return props.placeholder
  },
  // setter
  set(newValue) {
    emit('update:placeholder', newValue)
  },
})
</script>

<style scoped></style>
